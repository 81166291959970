<template>
    <section>
        <b-row>
            <b-col>

            </b-col>

            <b-button-toolbar aria-label="Toolbar with button groups and dropdown menu">
                <b-button-group class="mx-1">
                    <b-button @click="exportdf">Export</b-button>
                    <b-button @click="newNode">New</b-button>
                    <b-button>Undo</b-button>
                </b-button-group>
                <b-dropdown class="mx-1" right text="menu">
                    <b-dropdown-item>Item 1</b-dropdown-item>
                    <b-dropdown-item>Item 2</b-dropdown-item>
                    <b-dropdown-item>Item 3</b-dropdown-item>
                </b-dropdown>
                <b-button-group class="mx-1">
                    <b-button>Save</b-button>
                    <b-button>Cancel</b-button>
                </b-button-group>
            </b-button-toolbar>


        </b-row>
        <b-row>
            <b-col>
                <div id="drawflow" style="height: 800px"></div>

            </b-col>
        </b-row>



    </section>
</template>
        
<script>


import VueSlider from 'vue-slider-component'
import BrunaForm from "@/views/brunacomponents/BrunaForm2.vue";

import Drawflow from 'drawflow'
import Vue from 'vue'

import styleDrawflow from 'drawflow/dist/drawflow.min.css'
import HelloWorld from './HelloWorld.vue'
import HelloWorld2 from './HelloWorld2.vue'


import useApiServices from '@/services/useApiServices.js';



import axios from "@axios";
var qs = require('qs');

import
{
    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,

    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BDropdown,
    BDropdownItem,

    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,

    BOverlay,
    BSpinner


} from "bootstrap-vue";



export default {
    components: {

        BRow,
        BCol,
        BButtonToolbar,
        BButtonGroup,
        BButton,
        BInputGroup,
        BFormInput,
        BFormGroup,
        BFormTextarea,

        BCard,
        BCardTitle,
        BCardSubTitle,
        BCardBody,

        VueSlider,
        BMedia,
        BMediaAside,
        BAvatar,
        BMediaBody,


        BOverlay,
        BSpinner,

        BrunaForm,
        BDropdown,
        BDropdownItem,




    },

    methods: {

        exportdf()
        {
            let exportValue = this.editor.export();
            console.log(JSON.stringify(exportValue));
        },

        newNode()
        {
            this.editor.addNode('HelloWorld2', 1, 1, 150, 300, 'HelloWorld2', { select_type: "2" }, 'HelloWorld2', 'vue');

        }




    },




    mounted()
    {
        const id = document.getElementById("drawflow");
        this.editor = new Drawflow(id, Vue, this);

        //this.editor.start();

        //this.editor.registerNode('Node1', Node1, {}, {});
        //this.editor.registerNode('Node2', Node2, {}, {});
        //this.editor.registerNode('Node3', Node3, {}, {});

        //this.editor.import({"drawflow":{"Home":{"data":{"5":{"id":5,"name":"Node2","data":{"script":"(req,res) => {\n console.log(req);\n}"},"class":"Node2","html":"Node2","typenode":"vue","inputs":{"input_1":{"connections":[{"node":"6","input":"output_1"}]}},"outputs":{"output_1":{"connections":[]},"output_2":{"connections":[]}},"pos_x":1000,"pos_y":117},"6":{"id":6,"name":"Node1","data":{"url":"localhost/add", "method": "post"},"class":"Node1","html":"Node1","typenode":"vue","inputs":{},"outputs":{"output_1":{"connections":[{"node":"5","output":"input_1"}]}},"pos_x":137,"pos_y":89}}}}})

        this.editor.registerNode('HelloWorld', HelloWorld, { select_type: "2" }, {});
        this.editor.registerNode('HelloWorld2', HelloWorld2, {}, {});

        this.editor.useuuid = true

        this.editor.start();

        //this.editor.addNode('HelloWorld', 1, 1, 150, 300, 'HelloWorld', { select_type: "1" }, 'HelloWorld', 'vue');
        //this.editor.addNode('HelloWorld2', 1, 1, 150, 300, 'HelloWorld2', { select_type: "2" }, 'HelloWorld2', 'vue');
        //this.editor.addNode('HelloWorld2', 1, 1, 150, 300, 'HelloWorld2', { select_type: "2" }, 'HelloWorld2', 'vue');


        //let decoded = JSON.parse('{"drawflow":{"Home":{"data":{"1":{"id":1,"name":"HelloWorld","data":{"select_type":"1"},"class":"HelloWorld","html":"HelloWorld","typenode":"vue","inputs":{"input_1":{"connections":[]}},"outputs":{"output_1":{"connections":[{"node":"3","output":"input_1"},{"node":"2","output":"input_1"}]}},"pos_x":150,"pos_y":300},"2":{"id":2,"name":"HelloWorld2","data":{"select_type":"2"},"class":"HelloWorld2","html":"HelloWorld2","typenode":"vue","inputs":{"input_1":{"connections":[{"node":"1","input":"output_1"}]}},"outputs":{"output_1":{"connections":[]}},"pos_x":811,"pos_y":363},"3":{"id":3,"name":"HelloWorld2","data":{"select_type":"2"},"class":"HelloWorld2","html":"HelloWorld2","typenode":"vue","inputs":{"input_1":{"connections":[{"node":"1","input":"output_1"}]}},"outputs":{"output_1":{"connections":[]}},"pos_x":920,"pos_y":96}}}}}')
        //this.editor.import(decoded)


        this.editor.on('nodeSelected', function (id)
        {
            console.log("nodeSelected " + id);
        })

        this.editor.on('connectionCreated', function (data)
        {
            console.log("connectionCreated ", { data });
        })

        this.editor.on('connectionSelected', function (data)
        {
            console.log("connectionSelected ", { data });
        })

        this.editor.on('click', function (event)
        {
            console.log("click ", { event });
        })        




    },




    created()
    {




    },


    data()
    {

        let debug = (this.$route.query.debug == '1')


        return {


            showOverlay: false,

            editor: null




        };
    },
};
</script>
<style lang="scss" scoped>
span {
    font-size: 14px;
}
</style>
      
      
<style lang="scss" >
@import "@core/scss/vue/libs/vue-slider.scss";

.escenario-base {
    background-color: #00bcd4 !important;
}
</style>
      
<style lang="scss" scoped>
.card {
    .card-title {
        margin-bottom: 1.5rem !important;
    }

    ::v-deep .card-header {
        .heading-elements {
            position: static;
            cursor: inherit;

            .list-inline {
                display: block;

                li {
                    a {
                        padding: 0;
                    }

                    &:not(:last-child) {
                        margin-right: 1rem;
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss">
:root {
    --border-color: #cacaca;
    --background-color: #ffffff;

    --background-box-title: #f7f7f7;
}

html,
body {
    margin: 0px;
    padding: 0px;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    font-family: 'Roboto', sans-serif;
}

header {
    height: 66px;
    border-bottom: 1px solid var(--border-color);
    padding-left: 20px;
}

header h2 {
    margin: 0px;
    line-height: 66px;
}

header a {
    color: black;
}

.them-edit-link {
    position: absolute;
    top: 10px;
    right: 100px;
    color: black;
    font-size: 40px;
}

.them-edit-link a {
    text-decoration: none;
}

.github-link {
    position: absolute;
    top: 10px;
    right: 20px;
    color: black;
}

.wrapper {
    width: 100%;
    height: calc(100vh - 67px);
    display: flex;
}

.col {
    overflow: auto;
    width: 300px;
    height: 100%;
    border-right: 1px solid var(--border-color);
}

.drag-drawflow {
    line-height: 50px;
    border-bottom: 1px solid var(--border-color);
    padding-left: 20px;
    cursor: move;
    user-select: none;
}

.menu {
    position: absolute;
    height: 40px;
    display: block;
    background: white;
    width: 100%;
}

.menu ul {
    padding: 0px;
    margin: 0px;
    line-height: 40px;
}

.menu ul li {
    display: inline-block;
    margin-left: 10px;
    border-right: 1px solid var(--border-color);
    padding-right: 10px;
    line-height: 40px;
    cursor: pointer;
}

.menu ul li.selected {
    font-weight: bold;
}

.btn-export {
    float: right;
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    font-weight: bold;
    border: 1px solid #0e5ba3;
    background: #4ea9ff;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    z-index: 5;
}

.btn-clear {
    float: right;
    position: absolute;
    top: 10px;
    right: 85px;
    color: white;
    font-weight: bold;
    border: 1px solid #96015b;
    background: #e3195a;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    z-index: 5;
}

.swal-wide {
    width: 80% !important;
}

.btn-lock {
    float: right;
    position: absolute;
    bottom: 10px;
    right: 140px;
    display: flex;
    font-size: 24px;
    color: white;
    padding: 5px 10px;
    background: #555555;
    border-radius: 4px;
    border-right: 1px solid var(--border-color);
    z-index: 5;
    cursor: pointer;
}

.bar-zoom {
    float: right;
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    font-size: 24px;
    color: white;
    padding: 5px 10px;
    background: #555555;
    border-radius: 4px;
    border-right: 1px solid var(--border-color);
    z-index: 5;
}

.bar-zoom svg {
    cursor: pointer;
    padding-left: 10px;
}

.bar-zoom svg:nth-child(1) {
    padding-left: 0px;
}

#drawflow {
    position: relative;
    width: calc(100vw - 301px);
    height: calc(100% - 50px);
    top: 40px;
    background: var(--background-color);
    background-size: 25px 25px;
    background-image:
        linear-gradient(to right, #f1f1f1 1px, transparent 1px),
        linear-gradient(to bottom, #f1f1f1 1px, transparent 1px);
}

@media only screen and (max-width: 768px) {
    .col {
        width: 50px;
    }

    .col .drag-drawflow span {
        display: none;
    }

    #drawflow {
        width: calc(100vw - 51px);
    }
}



/* Editing Drawflow */

.drawflow .drawflow-node {
    background: var(--background-color);
    border: 1px solid var(--border-color);
    -webkit-box-shadow: 0 2px 15px 2px var(--border-color);
    box-shadow: 0 2px 15px 2px var(--border-color);
    padding: 0px;
    width: 200px;
}

.drawflow .drawflow-node.selected {
    background: white;
    border: 1px solid #4ea9ff;
    -webkit-box-shadow: 0 2px 20px 2px #4ea9ff;
    box-shadow: 0 2px 20px 2px #4ea9ff;
}

.drawflow .drawflow-node.selected .title-box {
    color: #22598c;
    /*border-bottom: 1px solid #4ea9ff;*/
}

.drawflow .connection .main-path {
    stroke: #4ea9ff;
    stroke-width: 3px;
}

.drawflow .drawflow-node .input,
.drawflow .drawflow-node .output {
    height: 15px;
    width: 15px;
    border: 2px solid var(--border-color);
}

.drawflow .drawflow-node .input:hover,
.drawflow .drawflow-node .output:hover {
    background: #4ea9ff;
}

.drawflow .drawflow-node .output {
    right: 10px;
}

.drawflow .drawflow-node .input {
    left: -10px;
    background: white;
}

.drawflow>.drawflow-delete {
    border: 2px solid #43b993;
    background: white;
    color: #43b993;
    -webkit-box-shadow: 0 2px 20px 2px #43b993;
    box-shadow: 0 2px 20px 2px #43b993;
}

.drawflow-delete {
    border: 2px solid #4ea9ff;
    background: white;
    color: #4ea9ff;
    -webkit-box-shadow: 0 2px 20px 2px #4ea9ff;
    box-shadow: 0 2px 20px 2px #4ea9ff;
}

.drawflow-node .title-box {
    height: 50px;
    line-height: 50px;
    background: var(--background-box-title);
    border-bottom: 1px solid #e9e9e9;
    border-radius: 4px 4px 0px 0px;
    padding-left: 10px;
}

.drawflow .title-box svg {
    position: initial;
}

.drawflow-node .box {
    padding: 10px 20px 20px 20px;
    font-size: 14px;
    color: #555555;

}

.drawflow-node .box p {
    margin-top: 5px;
    margin-bottom: 5px;
}

.drawflow-node.welcome {
    width: 250px;
}

.drawflow-node.slack .title-box {
    border-radius: 4px;
}

.drawflow-node input,
.drawflow-node select,
.drawflow-node textarea {
    border-radius: 4px;
    border: 1px solid var(--border-color);
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    width: 158px;
    color: #555555;
}

.drawflow-node textarea {
    height: 100px;
}


.drawflow-node.personalized {
    background: red;
    height: 200px;
    text-align: center;
    color: white;
}

.drawflow-node.personalized .input {
    background: yellow;
}

.drawflow-node.personalized .output {
    background: green;
}

.drawflow-node.personalized.selected {
    background: blue;
}

.drawflow .connection .point {
    stroke: var(--border-color);
    stroke-width: 2;
    fill: white;

}

.drawflow .connection .point.selected,
.drawflow .connection .point:hover {
    fill: #4ea9ff;
}


/* Modal */
.modal {
    display: none;
    position: fixed;
    z-index: 7;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.7);

}

.modal-content {
    position: relative;
    background-color: #fefefe;
    margin: 15% auto;
    /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 400px;
    /* Could be more or less, depending on screen size */
}

/* The Close Button */
.modal .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .modal-content {
        width: 80%;
    }
}
</style>
        