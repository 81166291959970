<template>
    <div>
        <div class="title-box"><i class="fab fa-telegram-plane"></i> HelloWorld</div>
        <div class="box">
            <select df-select_type @change="onMyChange">
                <option value="">Select</option>
                <option value="1">Select type 1</option>
                <option value="2">Select type 2</option>
                <option value="3">Select type 3</option>
            </select>
            <div v-if="select_type">
                <p>Select type: {{ select_type }}</p>
            </div>

        </div>

    </div>
</template>
    
<script>
export default {
    name: 'HelloWorld',
    props: {
        select_type: String,
    },

    mounted()
    {
        console.log(this._props)
    },
    methods: {
        'onMyChange': function ()
        {
            console.log(`New select type: ${this.select_type}`);
        },
    }
}
</script>